import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { getCookie } from 'cookies-next';
import { useSelector } from 'react-redux';

import { RootState } from '@/store';
import styles from './style.module.scss';
const FreeDelivery = dynamic(() => import('./free-delivery'));
import { getIsUserAuthorized } from '@/store/features/userSlice';
const WsButton = dynamic(() => import('@/components/common/WsButton'));
import { storeLocationService } from '@/services/storeLocationService';
import { getFastChange, getFastUpdate } from '@/store/features/commonSlice';

const DiscountStrip: React.FC = () => {
  const isFastChange = useSelector(getFastChange);
  const isFastUpdate = useSelector(getFastUpdate);
  const [loading, setLoading] = useState(false);
  const [pinError, setPinError] = useState(false);
  const [showView, setShowView] = useState<any>({});
  const [address, setAddress] = useState<string>('');
  const [pinText, setPinText] = useState<string>('');
  const [isTextEnable, setEnabletext] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [location, setLocation] = useState<string[]>([]);
  const [isShowPinMenu, setShowPinMenu] = useState(false);
  const isUserAuthorized = useSelector(getIsUserAuthorized);
  const stores = useSelector((state: RootState) => state.stores);
  const [isShowRelateStore, setShowRelateStore] = useState(false);
  const [pinLable, setPinLable] = useState<string>('Enter Pincode');
  const [placeHolder, setPlaceHolder] = useState<string>('313001,Udaipur');

  // const [latitude, setLatitude] = useState<string>('');
  // const [longitude, setLongitude] = useState<string>('');
  useEffect(() => {
    setLocations();
    // getLocation();
  }, [stores]);
  const setLocations = () => {
    const city = getCookie('city');
    const pin = getCookie('pincode');
    if (pin && city) {
      setPinLable(pin + ' , ' + city);
      setPlaceHolder(pin + ', ' + city);
      if (localStorage.location) {
        const location = JSON.parse(localStorage.location);
        if (location?.length > 0) {
          if (location?.length > 5) {
            setShowView({
              show: true,
              href: '/furniture-store-' + location[0]?.city?.toLowerCase(),
            });
            location.splice(6, location.length - 1);
            setLocation(location);
          } else setLocation(location);
          setShowRelateStore(true);
        }
      }
    } else {
      setLocation([]);
      setEnabletext(false);
      setPinLable('Enter Pincode');
      setPlaceHolder('313001,Udaipur');
    }
  };

  useEffect(() => {
    const exp = localStorage.getItem('expressSession');
    if (exp) {
      const espJson = JSON.parse(exp);
      setPinText(espJson.pincode);
      const txt = ': ' + espJson.pincode + ', ' + espJson.city;
      setAddress(txt);
    }
  }, [isFastChange]);
  useEffect(() => {
    if (isFastUpdate) {
      const exp = localStorage.getItem('expressSession');
      if (exp) {
        const espJson = JSON.parse(exp);
        const pin = getCookie('pincode');
        if (!pin || pin !== espJson.pincode)
          handleSubmit(false, espJson.pincode);
      }
    }
  }, [isFastUpdate]);

  // const getLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.permissions
  //       .query({ name: 'geolocation' })
  //       .then((permissionStatus) => {
  //         if (permissionStatus.state === 'denied') {
  //           console.log('then ~ permissionStatus:', permissionStatus);
  //         } else {
  //           navigator.geolocation.getCurrentPosition(
  //             (success: any) => {
  //               console.log('then ~ success:', success);
  //               // setLatitude(success.coords.latitude);
  //               // setLongitude(success.coords.longitude);
  //             },
  //             (error) => {
  //               console.log(error);
  //             }
  //           );
  //         }
  //       });
  //   } else {
  //     alert('Geolocation is not supported in your browser.');
  //   }
  // };

  const handlePinMenu = (e: any) => {
    e?.preventDefault();
    setPinError(false);
    setShowPinMenu(!isShowPinMenu);
    setPinText('');
    const city = getCookie('city');
    const pin = getCookie('pincode');
    if (pin && city) setEnabletext(false);
    else setEnabletext(true);
  };
  const handlePinText = (e: any) => {
    e.preventDefault();
    const text = e.target.value;
    setLoading(false);
    setPinError(false);
    if (pinText?.length < 6 || text.length < 6) setPinText(text);
  };
  const handleEnableText = () => {
    setPinText('');
    setPlaceHolder('');
    setLoading(false);
    setPinError(false);
    setEnabletext(!isTextEnable);
  };

  const handleSubmit = async (e: any, pin?: any) => {
    try {
      e && e?.preventDefault();
      setLoading(true);
      if (pinText?.length === 6 || pin?.length === 6) {
        setEnabletext(false);
        const res = await storeLocationService.getStoreLocation(pinText || pin);
        if (res.code == 200 && res?.data?.rows?.length > 0) {
          setShowRelateStore(true);
          setPinText('');
        } else {
          setPinError(true);
          setLocation([]);
          setPlaceHolder('');
          setEnabletext(true);
          setShowRelateStore(true);
          setPinLable('Enter Pincode');
        }
      } else setPinError(true);
    } catch (error) {
      setPinError(true);
      console.log('handleSubmit ~ error:', error);
    }
    setLoading(false);
  };
  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className={styles.topheaderSection}>
      <div className="container-1240">
        <div className={styles.topheaderInner}>
          <ul className={styles.topheaderLeft}>
            <li>
              <div className={styles.topLocation}>
                <Image
                  src={'/images/header/location-icon.svg'}
                  alt="media"
                  width={23}
                  height={23}
                  loading="eager"
                  priority
                />
                <p>
                  <span> Find a Store </span> - {pinLable}
                </p>
                <Link
                  href="#"
                  className={styles.editIcon}
                  onClick={handlePinMenu}
                >
                  <Image
                    src={'/images/header/topedit-img.svg'}
                    alt="media"
                    width={18}
                    height={18}
                    loading="eager"
                    priority
                  />
                </Link>
              </div>
              {isShowPinMenu && (
                <div className={styles.topheaderapply}>
                  <Link
                    href="#"
                    className={styles.Closeapply}
                    onClick={handlePinMenu}
                  >
                    <Image
                      src={'/images/header/apply-close.svg'}
                      alt="media"
                      width={25}
                      height={25}
                      loading="eager"
                      priority
                    />
                  </Link>
                  <form onSubmit={handleSubmit}>
                    <div className={styles.topapplycard}>
                      <label className={styles.field__label}>Pincode</label>
                      <input
                        id="search-user"
                        className={styles.formControl}
                        type="number"
                        disabled={!isTextEnable}
                        value={pinText}
                        onChange={handlePinText}
                        placeholder={placeHolder}
                      />
                      {isTextEnable && (
                        <WsButton
                          type="submit"
                          className={styles.applybtn}
                          isLoading={loading}
                        >
                          Apply
                        </WsButton>
                      )}
                      {!isTextEnable && (
                        <button
                          type="button"
                          className={styles.applybtn}
                          onClick={handleEnableText}
                        >
                          Change
                        </button>
                      )}
                    </div>
                    {pinError && (
                      <p className={styles.pinError}>
                        {pinText?.length < 6
                          ? 'Please enter a valid Pincode'
                          : 'Delivery option not available on requested area.'}
                      </p>
                    )}
                  </form>
                  {isShowRelateStore && !pinError && location?.length > 0 && (
                    <div className={styles.relatestore}>
                      <b>Nearest Woodenstreet Stores</b>
                      <ul className={styles.storeBox}>
                        {showView?.show && (
                          <li className={styles.storesort} data-sort="100">
                            <Link
                              id="store1000"
                              target="_blank"
                              href={showView.href}
                              className={styles.view}
                            >
                              View All
                            </Link>
                          </li>
                        )}
                        {location?.map(
                          (place: any, i: number) =>
                            place?.latitude &&
                            place?.longitude && (
                              <li className={styles.storesort} key={i}>
                                <Link
                                  target="_blank"
                                  id="store309"
                                  href={'/' + place.url}
                                >
                                  <Image
                                    src={'/images/header/location.svg'}
                                    alt="media"
                                    width={25}
                                    height={25}
                                    loading="eager"
                                    priority
                                  />
                                  <span>
                                    {place?.display?.trim() ??
                                      place?.store_address?.trim()}
                                  </span>
                                </Link>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </li>
            <li onClick={handleToggleModal}>
              <div className={styles['fast-delivery']}>
                <svg
                  width={28}
                  height={25}
                  viewBox="0 0 78 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M52.1857 8.57422V44.4989H31.5255C31.4211 42.878 30.7037 41.3575 29.5188 40.2465C28.3339 39.1355 26.7706 38.5172 25.1462 38.5172C23.5219 38.5172 21.9586 39.1355 20.7737 40.2465C19.5888 41.3575 18.8713 42.878 18.767 44.4989H14.7236V8.57422H52.1857Z"
                    stroke="#f3601e"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M73.4781 31.8199V44.4992H69.2253C69.1209 42.8782 68.4034 41.3579 67.2186 40.2468C66.0337 39.1357 64.4702 38.5174 62.846 38.5174C61.2217 38.5174 59.6584 39.1357 58.4735 40.2468C57.2886 41.3579 56.571 42.8782 56.4667 44.4992H52.1875V19.1406H61.0894L73.4781 31.8199Z"
                    stroke="#f3601e"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M73.4781 31.8203V44.4996H69.2253C69.1209 42.8786 68.4034 41.3582 67.2186 40.2472C66.0337 39.1361 64.4702 38.5178 62.846 38.5178C61.2217 38.5178 59.6584 39.1361 58.4735 40.2472C57.2886 41.3582 56.571 42.8786 56.4667 44.4996H52.1875V31.8203H73.4781Z"
                    stroke="#f3601e"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M25.1338 50.8394C28.6642 50.8394 31.5262 48.001 31.5262 44.4998C31.5262 40.9985 28.6642 38.1602 25.1338 38.1602C21.6033 38.1602 18.7412 40.9985 18.7412 44.4998C18.7412 48.001 21.6033 50.8394 25.1338 50.8394Z"
                    stroke="#f3601e"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M62.8339 50.8394C66.3643 50.8394 69.2263 48.001 69.2263 44.4998C69.2263 40.9985 66.3643 38.1602 62.8339 38.1602C59.3034 38.1602 56.4414 40.9985 56.4414 44.4998C56.4414 48.001 59.3034 50.8394 62.8339 50.8394Z"
                    stroke="#f3601e"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.28711 12.8008H9.56853"
                    stroke="#f3601e"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.29004 21.2539H9.57145"
                    stroke="#f3601e"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.29004 29.7051H9.57145"
                    stroke="#f3601e"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Fast Delivery {address}
              </div>
            </li>
          </ul>
          <ul className={styles.topheaderRight}>
            <li>
              <Link
                href={'tel:+91-9314444747'}
                className={styles.topheaderLinks}
                target="_blank"
              >
                <Image
                  src={'/images/header/top-phone.svg'}
                  alt="media"
                  width={15}
                  height={15}
                  loading="eager"
                  priority
                />
                <p>+91-9314444747</p>
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                className={styles.topheaderLinks}
                href={isUserAuthorized ? '/order-history' : '/track-your-order'}
              >
                <Image
                  src={'/images/header/TrackOrder.svg'}
                  alt="media"
                  width={23}
                  height={23}
                  loading="eager"
                  priority
                />
                <p> Track Order</p>
              </Link>
            </li>
            <li>
              <Link
                href="/help-center"
                className={styles.topheaderLinks}
                target="_blank"
              >
                <Image
                  src={'/images/header/help-center.svg'}
                  alt="media"
                  width={17}
                  height={17}
                  loading="eager"
                  priority
                />
                <p> Help Center</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {isModalOpen && (
        <FreeDelivery
          open={isModalOpen}
          handleModal={handleToggleModal}
          setPin={setLocations}
        />
      )}
    </div>
  );
};

export default DiscountStrip;
